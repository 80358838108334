(function ($) {
  Drupal.behaviors.basicGridFormatterV1 = {
    attach: function (context) {
      var $modules = $('.js-basic-grid-formatter--v1', context);

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-grid-details', $module);
        var $reveal = $('.js-grid-reveal', $module);
        var $expandableVideoContent = $('.js-expandable-video-grid', $module);
        var $expandableVideoOverlay = $('.js-video-overlay-outter', $module);
        var $expandableVideoPopup = $('.js-video-overlay', $module);
        var $closePopup = $('.js-close', $module);
        var $videoPlayer = $('.js-videojs-player', $module);
        var $videoElement = $videoPlayer.closest('.js-videojs-video');
        var $html = $module.closest('html');
        var dataSetup = $videoPlayer.find('.video-js').data('setup');
        var enableAutoplay = dataSetup && dataSetup.autoplay && dataSetup.muted;
        var $expandableVideoOpen = $expandableVideoContent.hasClass('js-disable-media-tile-click') ? $expandableVideoContent.find('.js-expandable-video-cta') : $expandableVideoContent;

        $trigger.on('click.details', function (event) {
          event.preventDefault();
          $module.toggleClass('grid-details-active');
        });

        if ($expandableVideoContent.hasClass('expandable_video_grid')) {
          $modules.addClass('expandable_video_content');
          $videoElement.addClass('not-interacted');
          if (!enableAutoplay) {
            $videoPlayer.trigger('start-video');
          }
          $videoPlayer.trigger('video-pause');
          $(document).on('video-play', '.not-interacted', function () {
            $videoPlayer.trigger('video-pause');
            $videoElement.removeClass('videojs-video--loading');
          });
        }

        $expandableVideoOpen.once().on('click', function (e) {
          e.preventDefault();
          $expandableVideoPopup.show();
          $expandableVideoOverlay.show();
          if (enableAutoplay) {
            $videoElement.removeClass('not-interacted');
            $videoPlayer.trigger('video-play');
          }
          $videoElement.removeClass('not-interacted');
          $html.addClass('disable-scrolling');
        });

        $closePopup.once().on('click', function (e) {
          e.preventDefault();
          $expandableVideoPopup.hide();
          $expandableVideoOverlay.hide();
          $videoPlayer.trigger('video-pause');
          $html.removeClass('disable-scrolling');
        });

        $(document).on('keyup', function (e) {
          if (e.keyCode === 27) {
            e.preventDefault();
            $closePopup.trigger('click');
          }
        });
      });
    }
  };
})(jQuery);
